import React, { useEffect, useState } from 'react';
import './App.css';
import "./helpers/interceptor"

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "./auth-config";
import Home from './components/base/Home';
import Login from './components/base/Login';
import CompanySelectionModal from './components/base/CompanyModel';
import { authenticate, getUserRoleMenuMapping } from './services/user-service';

function App() {

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [validUser, setValidUser] = useState();
  const [userCompany, setUserCompany] = useState("")
  const [idToken, setIdToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [show, setShow] = useState(false);
  let userCompanyData;

  let user_role;
  useEffect(() => {
    if (isAuthenticated) {
      RequestProfileData();
    }
    if (idToken && idToken !== "") {
      sessionStorage.setItem("Token", idToken);
      getUserData();
    }
  }, [isAuthenticated, idToken]); // eslint-disable-line react-hooks/exhaustive-deps
  function RequestProfileData() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setIdToken(response.idToken);
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          setIdToken(response.idToken);
        });
      });
  }
  async function getUserData() {
    console.log("Inside Accounts Data", accounts[0])
    let email = accounts[0].username;
    let userid = accounts[0].username.split("@")[0];
    console.log(" userid ", userid, " email ", email, " ", accounts[0], " ,,,,,,,,,,,,,,,,,,,,,,,,,accounts[0] ,,,,,,,,,,,,,,,,,,,,,,,,,,,,");

    let result = await authenticate(userid).catch((err) => {
      console.log("Error in fetching details", err)
      setValidUser(false);
      setErrorMessage(err)
      sessionStorage.clear()
    }
    );
    console.log("***********************result**************** ", result,);
    if (result && result?.UserData[0] && result?.UserData[0] !== "") {
      let userData = result.UserData[0];
      user_role = userData.user_role ? userData.user_role : "";
      console.log(" userData ", userData);
      sessionStorage.setItem("Role", userData.user_role ? userData.user_role : "");
      sessionStorage.setItem("Token", result.Token);
      sessionStorage.setItem("Email", userData.user_email ? userData.user_email : "");
      sessionStorage.setItem("UserId", userData.user_id ? userData.user_id : "");
      sessionStorage.setItem("CompanyData", userData.company_code ? userData.company_code.split(",") : []);
      userCompanyData = userData.company_code ? userData.company_code.split(",") : [];
      console.log(" userCompanyData ", userCompanyData);
      console.log(" userCompanyData ", userCompanyData.length);
      if (userCompanyData.length > 0) {       
        let userselection = sessionStorage.getItem("SelectedCompanyCode");
        if (userCompanyData.length == 1) {
         await handleSubmit(userCompanyData[0]);         
        }
        else {
          if (userselection == "" || userselection == undefined) {
            setShow(true);
          }
          else {
            setShow(false);
            handleSubmit(userselection);
          }
        }
        setValidUser(true);
      }
      else {
        setErrorMessage("Company code is not mapped to your Id")
        handleClose();
      }
    }
  else{
    setErrorMessage("Unable to process the request.")
  }
  }

  const handleClose = () => {
    setShow(false);
    setValidUser(false);

  };
  const handleSubmit = async (selectedCompany) => {
    if (selectedCompany) {
      console.log(" selectedCompany ", selectedCompany)
      let selected_company_code = selectedCompany.split("-")[0]
      sessionStorage.setItem("SelectedCompanyCode", selected_company_code);
      setUserCompany(selected_company_code);
      let result = await getUserRoleMenuMapping(sessionStorage.getItem("Role"), selected_company_code).catch((err) => {
        console.log("Error in fetching details", err)
        setValidUser(false);
        setErrorMessage(err)
        sessionStorage.clear()
      }
      );
      console.log(" result getUserRoleMenuMapping", result)
      console.log(" result getUserRoleMenuMapping", result[0])
      console.log(result[0], "***********", result, "*********************** getUserRoleMenuMapping result getUserRoleMenuMapping**************** ", " ", JSON.stringify(result));
      if (result && result[0] && result[0] !== "") {
        console.log("Setting AuthorizedSessions ",)
        sessionStorage.setItem("AuthorizedSessions", JSON.stringify(result))
        console.log(sessionStorage.getItem("AuthorizedSessions"));
      }
      else {
        sessionStorage.setItem("AuthorizedSessions", [])
      }
      setShow(false);
    }
    else {
      setValidUser(false);
    }
  }

  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        {validUser === true && show === true && (
          <CompanySelectionModal
            handleSubmit={handleSubmit}
            show={show}
            handleClose={handleClose}
            userCompanyData={userCompanyData}
          />
        )

        }
        {validUser === true && show === false && (
          <Home userCompany={userCompany} />
        )
        }
        {validUser === false && (
          <Login errorMessage={errorMessage} openModal={false} userCompanyData={[]} />
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </React.Fragment>
  );
}

export default App;
