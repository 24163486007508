import { Route, Routes } from "react-router-dom";
import { Container, Spinner } from "react-bootstrap";
import { Suspense, lazy } from "react";
const Dashboard = lazy(() => import("../Dashboard/Dashboard"));
const UserMaster = lazy(() => import("../Admin/UserMaster"));
const RoleMaster = lazy(() => import("../Admin/RoleMaster"));
const ItemMaster = lazy(() => import("../Masters/ItemCodeMaster"));
const ProjectMaster = lazy(() => import("../Masters/ProjectMaster"));
const ProjectCostTypeMaster = lazy(() => import("../Masters/ProjectCostTypeMaster"));
const CurrencyExchangeRateMaster = lazy(() => import("../Masters/CurrencyExchangeRateMaster"));
const ProductCostMaster = lazy(() => import("../Masters/ProductCostMaster"));
const SoDetailsMaster = lazy(() => import("../Masters/SoDetailsMaster"));
const SoSummaryMaster = lazy(() => import("../Masters/SoSummaryMaster"));
const BusinessCodeMaster = lazy(() => import("../Masters/BusinessCodeMaster"));
const WorkflowMatrixMaster = lazy(() => import("../Masters/WorkflowMatrixMaster"));
const EstimatedCostMaster = lazy(() => import("../Transaction/EstimatedCostMaster"));
const ActualProjectCostMaster = lazy(() => import("../Transaction/ActualProjectCostMaster"));
const SimulatePOCForm = lazy(() => import("../Transaction/SimulatePOCForm"));
const JVGeneration = lazy(() => import("../Transaction/JVGeneration"));
const QuarterlyEstimateConfirmationMaster = lazy(()=> import("../Transaction/QuarterlyEstimateConfirmationMaster"))





export default function Main(props) {
  return (
    <Container bsPrefix="main">
      <Routes>
        <Route exact path="/" element={<Suspense fallback={
          <div className="spinner-box">
            <Spinner animation="border" className="spinner"></Spinner>
          </div>}><Dashboard/></Suspense>}></Route>
          
        <Route exact path="/usermaster" element={<Suspense fallback={
          <div className="spinner-box">
            <Spinner animation="border" className="spinner"></Spinner>
          </div>}><UserMaster /></Suspense>}></Route>
          <Route exact path="/roleauthorization" element={<Suspense fallback={
          <div className="spinner-box">
            <Spinner animation="border" className="spinner"></Spinner>
          </div>}><RoleMaster /></Suspense>}></Route>
          <Route exact path="/itemmaster" element={<Suspense fallback={
          <div className="spinner-box">
            <Spinner animation="border" className="spinner"></Spinner>
          </div>}><ItemMaster /></Suspense>}></Route>
          <Route exact path="/projectmaster" element={<Suspense fallback={
          <div className="spinner-box">
            <Spinner animation="border" className="spinner"></Spinner>
          </div>}><ProjectMaster /></Suspense>}></Route>
          <Route exact path="/projectcosttype" element={<Suspense fallback={
          <div className="spinner-box">
            <Spinner animation="border" className="spinner"></Spinner>
          </div>}><ProjectCostTypeMaster /></Suspense>}></Route>
          <Route exact path="/currencyexchangerate" element={<Suspense fallback={
          <div className="spinner-box">
            <Spinner animation="border" className="spinner"></Spinner>
          </div>}><CurrencyExchangeRateMaster /></Suspense>}></Route>
          <Route exact path="/productcost" element={<Suspense fallback={
          <div className="spinner-box">
            <Spinner animation="border" className="spinner"></Spinner>
          </div>}><ProductCostMaster /></Suspense>}></Route>
          <Route exact path="/sodetails" element={<Suspense fallback={
          <div className="spinner-box">
            <Spinner animation="border" className="spinner"></Spinner>
          </div>}><SoDetailsMaster /></Suspense>}></Route>
          <Route exact path="/sosummary" element={<Suspense fallback={
          <div className="spinner-box">
            <Spinner animation="border" className="spinner"></Spinner>
          </div>}><SoSummaryMaster /></Suspense>}></Route>
          <Route exact path="/workflowmatrix" element={<Suspense fallback={
          <div className="spinner-box">
            <Spinner animation="border" className="spinner"></Spinner>
          </div>}><WorkflowMatrixMaster /></Suspense>}></Route>
          <Route exact path="/businesscode" element={<Suspense fallback={
          <div className="spinner-box">
            <Spinner animation="border" className="spinner"></Spinner>
          </div>}><BusinessCodeMaster /></Suspense>}></Route>
          <Route exact path="/simulatepoc" element={<Suspense fallback={
          <div className="spinner-box">
            <Spinner animation="border" className="spinner"></Spinner>
          </div>}><SimulatePOCForm session="simulatepoc"/></Suspense>}></Route>
          <Route exact path="/estimatedcost" element={<Suspense fallback={
          <div className="spinner-box">
            <Spinner animation="border" className="spinner"></Spinner>
          </div>}><EstimatedCostMaster /></Suspense>}></Route>
          <Route exact path="/actualprojectcost" element={<Suspense fallback={
          <div className="spinner-box">
            <Spinner animation="border" className="spinner"></Spinner>
          </div>}><ActualProjectCostMaster /></Suspense>}></Route>
          <Route exact path="/jvgeneration" element={<Suspense fallback={
          <div className="spinner-box">
            <Spinner animation="border" className="spinner"></Spinner>
          </div>}><JVGeneration session="jvgeneration" /></Suspense>}></Route>
          <Route exact path="/quarterlyestimate" element={<Suspense fallback={
          <div className="spinner-box">
            <Spinner animation="border" className="spinner"></Spinner>
          </div>}><QuarterlyEstimateConfirmationMaster /></Suspense>}></Route>
      </Routes>
    </Container>
  );
}
