import { Image, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./NavItem.css"
import { ReactSVG } from "react-svg";
import ChevronDown from "../../assets/images/chevron-down.svg"
import ChevronUp from "../../assets/images/chevron-up.svg"

export default function NavItem(props) {
    let location = useLocation();
    let navigate = useNavigate();

    return (<>
        {props.menuType == "parent" && <Nav.Item as="li" className={`${props.menu} ${location.pathname == props.path ? "active" : "inactive"}`} onClick={() => {
            if (props.setOpen) {
                props.setOpen((prevState) => {
                    return {
                        ...prevState,
                        [props.menu]: props.open[props.menu] ? false : true
                    }
                })
            }
            if (props.path) { navigate(props.path) }
            props.setSidebar("open");
        }}>
            <Nav.Link as={Link} to={props.path} eventKey={props.menu} bsPrefix={`nav-list-item ${props.menuType}  ${location.pathname === props.path ? "active" : "inactive"}`} >
                {props.menuType === "parent" && <OverlayTrigger placement="right" overlay={<Tooltip>{props.menu}</Tooltip>}>
                    <ReactSVG className="sidebar-icon parent" src={props.icon}></ReactSVG>
                </OverlayTrigger>}
                <span className="menu-name">{props.menu}</span>
            </Nav.Link>

            {props.ariaControls && <> {!props.open[props.menu] && <ReactSVG src={ChevronDown} className="menu-arrow" onClick={() => {
                props.setOpen((prevState) => {
                    return {
                        ...prevState,
                        [props.menu]: true
                    }
                }
                )
            }} aria-controls={props.ariaControls}></ReactSVG>}
                {props.open[props.menu] && <ReactSVG src={ChevronUp} className="menu-arrow" onClick={() => {
                    props.setOpen((prevState) => {
                        return {
                            ...prevState,
                            [props.menu]: false
                        }
                    }
                    )
                }} aria-controls={props.ariaControls}></ReactSVG>}

            </>}
        </Nav.Item>}
        {props.menuType == "menu" && <Nav.Item as="li" className={`${location.pathname == props.path ? "active" : "inactive"}`} onClick={() => {
            navigate(props.path)
            if (window.innerWidth < 768) {
                props.setSidebar("closed")
            }
        }}
        >
            <Nav.Link as={Link} to={props.path} eventKey={props.menu} bsPrefix={`nav-list-item ${props.menuType}`} >
                <span className="menu-name">{props.menu}</span>
            </Nav.Link>
        </Nav.Item>}
    </>
    )

}