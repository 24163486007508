import {
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth-config";
import React, { useState } from "react";
import logo from "../../assets/POCRRLogo.svg";
import login1 from "../../assets/LoginImage.jpg";
import godrejLogo from "../../assets/Godrej-colour-logo.svg";

export default function Login(props) {
  const { instance } = useMsal();

  let companydata = props.userCompanyData;
  console.log(" companydata_Login", companydata);

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  }

  return (
   
    <div className="login-container">
      <Row className="place-right">
        <Col xs={1} md={8} className="d-none d-md-block d-lg-block" style={{"boxShadow":"0px 2px 30px rgba(0, 0, 0, 0.2)"}}>
          <div className="loginLeftImage">
            <img
              src={login1}
              alt="godrejLeftImage"
           
            />
          </div>
        </Col>
        <Col
          xs={12}
          md={4}        
        >
          <div className="LoginRightImage text-center px-4">
            <div>
              <img
                src={godrejLogo}
                alt="godrejLogo"
               
              />
            </div>
            <div>
              <img
                src={logo}
                alt="godrejLogo"
                className="LoginRightImageGodrej mt-5"
              />
            </div>
            <div className="mt-3">
              {props.errorMessage && (
                <div className="loginErrorDiv">
                  <div className="loginErrorDivHeader">Login Failed</div>
                  <div className="loginErrorDivBody">{props.errorMessage}</div>
                </div>
              )}
            </div>
            <div className="LoginRightInput mt-5">
              <Button
                className="forgotButton"
                onClick={() => handleLogin(instance)}
              >
                Login
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
